import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/mnt/d/ShapeGanicGatsby/src/components/blog/layout.js";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import BlogOverview from "../../../components/blog/overview/blogOverview.de.mdx";
import Layout from "../../../components/blog/layout.de";
export const query = graphql`
  {
    mdx(slug: { eq: "kalorienzaehler-app-kostenlos/index.de" }) {
      frontmatter {
        main_image {
          publicURL
        }
        image_introduction {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_introduction_alt
        image_introduction_desc
        image_introduction_credit_text
        image_introduction_credit_link
        image_first {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_first_alt
        image_second {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_second_alt
        image_third {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_third_alt
        date(formatString: "D. MMMM YYYY", locale: "de-DE")
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEO = makeShortcode("SEO");
const Title = makeShortcode("Title");
const Image = makeShortcode("Image");
const AppImage = makeShortcode("AppImage");
const AppPromotion = makeShortcode("AppPromotion");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO lang={props.pageContext.frontmatter.lang} description={props.pageContext.frontmatter.summary} title={props.pageContext.frontmatter.title} keywords={props.pageContext.frontmatter.keywords} imageUrl={props.data.mdx.frontmatter.main_image.publicURL} mdxType="SEO" />
    <Title title={props.pageContext.frontmatter.title} date={props.data.mdx.frontmatter.date} readingTime={props.data.mdx.fields.readingTime.minutes} mdxType="Title" />
    <p>
  <b>{props.pageContext.frontmatter.summary}</b> Eine kontrollierte Nahrungs-
  und insbesondere Kalorienzufuhr ist das Fundament, um Körperfett ab- oder
  Muskulatur aufzubauen. Das Zählen von Kalorien und Makronährstoffen hat sich
  hierbei als besonders effektiv erwiesen. Noch vor mehreren Jahren mussten
  Sportler alles händisch mit Taschenrechner und Papier ausrechnen und
  dokumentieren. Mit dem Erscheinen der ersten Kalorienzähler Apps hat sich
  dieser Aufwand erheblich verringert. Dennoch bleibt es eine lästige Arbeit
  täglich fast identische Lebensmitteleinträge zu erstellen. ShapeGanic stellt
  dir nun ein Feature vor, dass dich dein täglisches Tracking in wenigen
  Sekunden erledigen lässt.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_introduction)} imageAlt={props.data.mdx.frontmatter.image_introduction_alt} imageDesc={props.data.mdx.frontmatter.image_introduction_desc} imageAuthor={props.data.mdx.frontmatter.image_introduction_credit_text} imageLink={props.data.mdx.frontmatter.image_introduction_credit_link} mdxType="Image" />
    <h2>{`Dieses Feature macht dich effektiver!`}</h2>
    <p>
  Tatsächlich ist es die konstruktive Google Play Store Rezension eines Nutzers
  gewesen, der dieses Feature angeregt hat. Und da wir es uns auf die Fahne
  geschrieben haben, die Anregungen unserer Nutzer ernst zu nehmen, wurde diese
  Idee sofort auf die To-do-Liste gesetzt. Und nun können wir dir im Folgenden
  zeigen, wie du einfach mit wenigen Klicks Mahlzeiten von Vortagen übernehmen
  bzw. kopieren kannst.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_first)} imageAlt={props.data.mdx.frontmatter.image_first_alt} mdxType="AppImage" />
    <p>
  Wenn du bereits Einträge für vergangene Tage getrackt hast, erscheint in
  deinem Dashboard ein Button mit der Aufschrift "Einträge kopieren". Betätigst
  du diesen Button erscheint eine neue Ansicht, in der du oben aus einer
  horizontalen Liste einen vergangenen Tag als Vorlage auswählen kannst. Diesen
  Screen siehst du in der folgenden Abbildung.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_second)} imageAlt={props.data.mdx.frontmatter.image_second_alt} mdxType="AppImage" />
    <p>
  Nachdem du einen Vorlagentag ausgewählt hast, werden alle Mahlzeiteneinträge
  in der darunter liegenden Übersicht dargestellt. Du hast jetzt die Möglichkeit
  standardmäßig alle Einträge oder einzelne Mahlzeiten und Lebensmittel aus-
  oder abzuwählen. Beim Auswählen der entsprechenden Einträge erhälst du sofort
  ein visuelles Feedback bezüglich der Kalorien und Makronährstoffe.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_third)} imageAlt={props.data.mdx.frontmatter.image_third_alt} mdxType="AppImage" />
    <p>
  Die Mahlzeiten sind hierbei genauso sortiert, wie du es vom Dashboard gewohnt
  bist. Wenn du mit dem Auswählen der Mahlzeiten bzw. Lebensmittel fertig bist,
  scrollst du nur noch nach unten und betätigst den Button mit der Aufschrift
  "Duplizieren". Deine Einträge werden jetzt sofort kopiert und du gelangst
  wieder zu deinem Dashboard. Was in anderen Apps mitunter mehrere Minuten
  dauern und dazu noch sehr viele Fehler verursachen kann, hast du nun mit
  ShapeGanic innerhalb weniger Sekunden erledigt.
    </p>
    <h2>{`So erreichst du deine Ziele...`}</h2>
    <p>
  In diesem Artikel hast du gelernt, wie du mit ShapeGanic in kürzester Zeit
  Einträge aus bereits getrackten Lebensmitteln übernimmst. Konzentrier dich auf
  deine Diät, gib alles beim Training und den Rest übernehmen wir!
    </p>
    <AppPromotion mdxType="AppPromotion" />
    <BlogOverview mdxType="BlogOverview" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      